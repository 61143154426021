@import 'variables/stylesVariables';

.root {
  position: relative;
  box-sizing: border-box;

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;

    .calendar {
      position: sticky;
      top: 0;
    }

    .dateTitle {
      text-align: center;
      padding: 10px;
      font-size: 18px;
      font-weight: bold;
      position: sticky;
      top: 0;
      background-color: $mainBgColor;
      z-index: 10;
      box-sizing: border-box;
    }

    .schedule {
      box-sizing: border-box;
      position: relative;
      width: 100%;
    }
  }
}
