@import 'variables/stylesVariables';

.root {
  width: fit-content;
  position: relative;
  cursor: pointer;
}

.list {
  position: absolute;
  top: 110%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background-color: rgba(253, 253, 253, 0.9);
  padding: 10px 0;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  &__item {
    width: 100%;
    display: block;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: $primaryColor;
      transition: 0.2s;
    }

    &:hover a {
      color: #fff;
      transition: 0.2s;
    }

    a {
      text-decoration: none;
      font-size: 16px;
      padding: 8px 20px;
      width: 100%;
      color: $primaryColor;
    }
  }
}

.left {
  left: 0;
  right: unset;
}

.right {
  right: 0;
  left: unset;
}

.center {
  left: 50%;
  transform: translateX(-50%);
}
