@import 'variables/stylesVariables';

.backplate {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding-bottom: 80px;

  &__dark {
    background: $darkBgHeader;
    @include themeSwitchTransition;
  }
}

.root {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.19);
  min-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.title {
  font-size: 24px;
  margin-bottom: 24px;
  background-color: $primaryColor;
  box-shadow: 0px 2px 2px rgba(205, 140, 210, 0.19);
  box-sizing: border-box;
  color: $topMenuLink;
  width: 100%;
  text-align: center;
  padding: 15px;
}

.form {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 50px;
}

.input {
  width: 100%;
}

.bottomPanel {
  display: flex;
  justify-content: center;
  padding: 30px;
}

.sex {
  margin-top: 20px;

  &__select {
    width: 200px;
  }
}
