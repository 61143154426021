.root {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.overlay {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10;
  width: 100%;
  height: 100%;
}
