@import 'variables/stylesVariables';

$cellSize: 40px;

$hoverColor: rgba($primaryColor, 0.5);
$activeColor: $primaryColor;
$borderColor: rgba(#333, 0.2);

@mixin th-td-styles {
  width: $cellSize;
  height: $cellSize;
  min-width: $cellSize;
  min-height: $cellSize;
  text-align: center;
  box-sizing: border-box;
  background-color: #fff;
  user-select: none;
}

.root {
  min-width: fit-content;
  padding: 20px 15px;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  background-color: $mainBgColor;
  border: 1px solid $borderColor;
  user-select: none;

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    line-height: 1;
    user-select: none;
    margin-bottom: 15px;
    padding: 0 4px;
  }

  &__calendar {
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid $borderColor;
    background-color: #fff;

    table {
      border-collapse: collapse;

      thead {
        th {
          @include th-td-styles;
          background-color: #bbb;
          font-size: 14px;
          color: #fff;
        }
      }

      tbody {
        td {
          cursor: pointer;
          font-size: 12px;
          border-radius: 4px;
          @include th-td-styles;

          &:hover {
            background-color: $hoverColor;
            color: #fff;

            transition: 0.1s;
          }
        }
      }
    }
  }

  &_isSelected {
    background-color: $activeColor !important;
    color: #fff;
  }

  &_isOtherMonth {
    color: #bbb;
  }
}
