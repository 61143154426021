@import 'variables/stylesVariables';

.root {
  box-sizing: border-box;
  position: relative;
  border-radius: $borderRadius;
  box-shadow: $primaryBoxShadow;
  padding: 25px;
  overflow: hidden;

  .content {
    box-sizing: border-box;
    position: relative;
    flex-grow: 1;
  }

  &_isScrollable {
    .content {
      overflow: scroll;
      padding-right: 28px;
      height: 100%;
      @include scroll-styles;
    }
  }

  &_noPadding {
    padding-left: 0;
    padding-right: 0;
  }

  &_noBackground {
    border-radius: unset !important;
    background: transparent !important;
    box-shadow: unset !important;
    padding: 0px !important;
    overflow: unset !important;

    &.root {
      &_isDark {
        background: transparent !important;
      }
    }

    > .content {
      overflow: scroll !important;
      margin: -10px 0;
    }
  }

  &_isLink {
    cursor: pointer;
    transition: 0.1s all ease-in;

    > .bgIcon svg {
      transition: 0.1s all ease-in;
    }

    &:hover {
      &.default {
        background-color: $defaultCardHoverBgColor;
        color: $secondaryTextColor;

        > .bgIcon svg {
          color: $secondaryTextColor;
        }
      }

      &.blue {
        background-color: $blueCardHoverBgColor;
        color: #fff;

        > .bgIcon svg {
          color: #fff;
        }
      }

      &.dark {
        background-color: $darkCardHoverBgColor;
        color: #fff;

        > .bgIcon svg {
          color: #fff;
        }
      }

      &.grey {
        background-color: $greyCardHoverBgColor;
        color: #fff;

        > .bgIcon svg {
          color: #fff;
        }
      }
    }
  }

  &_isDark {
    .bgIcon svg {
      color: $mainBgColor_dark;
    }

    &.default {
      background-color: $cardColor_dark;
      color: $mainBgColor;
    }

    > .title {
      color: $secondaryTextColor_dark;
    }

    &.blue {
      background-color: $primaryColor;
    }

    &.dark {
      background-color: $primaryTextColor_dark;
    }

    &.grey {
      background-color: $mainIconColor;
    }

    &.blue,
    &.dark,
    &.grey {
      color: $mainBgColor_dark;

      > .title {
        color: $mainBgColor_dark;
      }
    }
  }
}

.title {
  color: $secondaryTextColor;
  font-size: 24px;
  font-weight: 500;
}

.default {
  background-color: $backplateColor;
  color: $primaryTextColor;
}

.blue {
  background-color: $cardBlueColor;
}

.dark {
  background-color: $cardDarkColor;
}

.grey {
  background-color: $cardGreyColor;
}

.blue,
.dark,
.grey {
  color: $backplateColor;

  > .title {
    color: $backplateColor;
  }
}

.bgIcon {
  position: absolute;
  bottom: 15%;
  right: 5%;
  width: 42%;
  height: 42%;

  > svg {
    width: 100%;
    height: 100%;
    color: $mainBgColor;
  }
}
