@import 'variables/stylesVariables';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 52px;
  height: 30px;
  background-color: #fff;
  border-radius: 30px;
  padding: 3px;
  position: relative;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  border: 1px solid $mainIconColor;
  box-sizing: border-box;

  &_right {
    flex-grow: 1;
    transition: flex 0.3s ease;
  }

  &_left {
    transition: flex 0.3s ease;
  }

  &__checkbox {
    width: 23px;
    height: 23px;
    background-color: $mainIconColor;
    border-radius: 30px;
    transition: flex 0.3s ease;
    box-sizing: border-box;
  }

  &__dark {
    border-color: $primaryTextColor;
    background-color: $primaryTextColor;

    .root__checkbox {
      background: #fff;
      @include themeSwitchTransition;
    }

    .root__right,
    .root__left {
      color: $darkBgColor;
      @include themeSwitchTransition;
    }
  }
}
