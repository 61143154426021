@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('../assets/fonts/Mulish-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
