@import 'variables/stylesVariables';

.errorMessage {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 200px;
  color: $secondaryTextColor;
}
