/** Colors */
$mainBgColor: #f0f2f5;
$backplateColor: #fff;
$primaryColor: #1976d2;
$primaryTextColor: #49454f;
$secondaryTextColor: #165695;
$mainIconColor: #757575;
$cardBlueColor: $primaryColor;
$cardDarkColor: $primaryTextColor;
$cardGreyColor: $mainIconColor;
$inputPlaceholderColor: #999;
$disabledButtonBgColor: #4b83ba;
$linkColor: $primaryColor;

/** Error/warning/info */
$errorColor: #b3261e;
$warningColor: #df6203;

/** Borders */
$primaryBorderColor: #b9b9b9;

/** Shaddows */
$primaryBoxShadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.2);
$primaryBoxShadow_dark: 2px 2px 5px 0px rgba(133, 133, 133, 0.2);

/** Dark theme */
$mainBgColor_dark: #3c3c3c;
$cardColor_dark: #232323;
$primaryTextColor_dark: #f0f0f0;
$secondaryTextColor_dark: #2484e2;
$darkBgColor: rgb(60, 64, 67);

/** Hovers */
$buttonHoverColor: $secondaryTextColor;
$defaultCardHoverBgColor: #f2fdff;
$blueCardHoverBgColor: #1f88ef;
$darkCardHoverBgColor: #6a6a6a;
$greyCardHoverBgColor: #939393;

/** Dimensions */
$headerHeight: 65px;
$sideSpace: 30px;
$borderRadius: 15px;

/** Sidebar */
$expandedWidth: 330px;
$tightWidth: 96px;

/** Fonts */
$mainFont: 'Mulish';

@mixin themeSwitchTransition {
  transition: 0.25s background-color ease-in;
}

@mixin scroll-styles {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $mainBgColor;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $primaryBorderColor;
  }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}

/** --------------------------------------- **/
/** СТАРЫЕ ПЕРЕМЕННЫЕ **/
/** Colors */
$mainColor: #ef777c;
$secondaryColor: #bd6ebb;
$headerBg: $mainColor;
$footerBg: $mainColor;
$defaultButtonBg: $mainColor;

/** Text */
$mainText: $mainColor;
$topMenuLink: rgba(255, 255, 255, 0.9);

/** Dark theme */
$darkBgHeader: rgb(36, 39, 41);
$darkBgFooter: $darkBgHeader;
$darkTextColor: #ddd;
$darkBorderColor: #888;
$darkBgContent: rgb(43, 47, 49);
$darkBgInput: #ddd;
$darkBgInputText: #333;
$darkBgInputPlaceholder: #777;

/** Box-shadows */
$mainShadow: 0px 2px 4px 3px rgba(34, 60, 80, 0.2);

$siteWidth: 1024px;
$siteWidthTab: 768px;
$siteWidthMobile: 500px;
$siteWidthMobileXS: 360px;
$footerHeight: 100px;
$sideSpace: 24px;

// 480px size
$sideSpace_480: 18px;
