@import 'variables/stylesVariables';

$inputEmptyHeight: 55px;
$inputFilledHeight: 35px;
$labelFilledHeight: $inputEmptyHeight - $inputFilledHeight;
$labelEmptyYPosition: $inputEmptyHeight - $inputFilledHeight;
$errorHeight: 24px;
$sidePadding: 16px;
$inputFilledPaddingTop: 10px;
$inputEmptyPaddingTop: $inputEmptyHeight - $inputFilledHeight + $inputFilledPaddingTop - 2px;

@mixin label-to-top {
  align-items: flex-end;
  transform: translateY(0);
  color: $primaryColor;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.4px;
  height: $labelFilledHeight;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
}

@mixin input-styles-init {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 400;
  padding-left: $sidePadding;
  padding-right: $sidePadding;
  box-sizing: border-box;
}

@mixin input-styles-filled {
  height: $inputFilledHeight;
  padding-top: $inputFilledPaddingTop;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  box-sizing: border-box;
  min-width: 210px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    height: $inputEmptyHeight + 1;
  }

  &_hasError {
    .root {
      &__label {
        color: $errorColor !important;
      }

      &__divider {
        border-color: $errorColor;
      }
    }
  }

  &_hasText {
    .root {
      &__input {
        @include input-styles-filled;
      }

      &__label {
        @include label-to-top;
      }
    }
  }

  &_isDisabled {
    .root {
      &__wrapper {
        background-color: $mainBgColor;
        border-radius: 4px 4px 0 0;
      }

      &__input {
        color: $mainIconColor;
        @include input-styles-filled;
      }

      &__label {
        @include label-to-top;
      }
    }
  }

  &__input {
    display: block;
    position: relative;
    border: none;
    background: unset;
    color: $primaryTextColor;
    outline: none;
    padding-top: $inputEmptyPaddingTop;
    padding-bottom: 8px;
    height: $inputEmptyHeight;
    @include input-styles-init;

    &:focus {
      @include input-styles-filled;
      height: $inputFilledHeight - 1;

      & + .root__label {
        @include label-to-top;
      }

      & + .root__label + .root__divider {
        border-bottom: 2px solid $primaryColor;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
      @include input-styles-filled;

      & + .root__label {
        @include label-to-top;
      }
    }
  }

  &__label {
    display: flex;
    align-items: center;
    order: -1;
    position: absolute;
    top: 0;
    transition: 0.2s transform, 0.2s font-size;
    color: $inputPlaceholderColor;
    pointer-events: none;
    transform: translateY($labelEmptyYPosition);
    height: $inputFilledHeight;
    @include input-styles-init;
  }

  &__divider {
    width: 100%;
    border-bottom: 1px solid $primaryTextColor;
    box-sizing: border-box;
  }

  &__error {
    position: relative;
    box-sizing: border-box;
    color: $errorColor;
    min-height: $errorHeight;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 14px;
  }

  &_isDark {
    .root {
      &__input {
        color: $primaryTextColor_dark;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          -webkit-text-fill-color: $primaryTextColor_dark;
        }

        &:focus + .root__label {
          color: $primaryTextColor_dark;
        }
      }

      &__divider {
        border-color: $primaryTextColor_dark;
      }
    }

    &.root_isDisabled {
      .root {
        &__wrapper {
          background: $mainBgColor_dark;
        }

        &__input {
          color: $mainIconColor;
        }
      }
    }
  }
}
