@import 'variables/stylesVariables';

$fieldVerticalGap: 10px;

.root {
  position: relative;
  box-sizing: border-box;
  padding: 0 165px 40px 85px;
}

.form {
  position: relative;
  box-sizing: border-box;
  display: block;
}

.block_1,
.block_1__left,
.block_1__right {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: $fieldVerticalGap;
}

.block_1 {
  display: flex;
  flex-direction: row;
  gap: 130px;
}

.block_2 {
  margin-top: $fieldVerticalGap;
}

.bottomPanel {
  margin-top: 36px;
}
