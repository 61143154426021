@import 'variables/stylesVariables';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  color: #fff;
  padding: 10px 20px 12px;
  line-height: 1;
  min-width: 130px;
  font-weight: 500;
  box-sizing: border-box;
  transition: 0.15s;
}

.xsmall {
  font-size: 12px;
  padding: 4px;
}

.small {
  font-size: 14px;
  padding: 8px;
}

.middle {
  font-size: 16px;
}

.large {
  font-size: 18px;
}

.default {
  background-color: $primaryColor;
  color: #fff;

  &:hover {
    box-shadow: 2px 2px 5px 0px $mainIconColor;
  }

  &.disabled,
  &.disabled:hover {
    background: $disabledButtonBgColor;
    color: #a0a0a0;
    cursor: default;
    box-shadow: unset;
  }
}

.link {
  background: none;
  color: $primaryColor;
  padding: 0;
  min-width: unset;
  line-height: 20px;
  text-align: left;

  &:hover {
    text-decoration: underline;
    background-color: unset;
  }

  &.disabled,
  &.disabled:hover {
    color: $mainIconColor;
    cursor: default;
    text-decoration: none;
  }
}

.transparent {
  background: unset;
  border: 1px solid $primaryColor;
  color: $primaryColor;

  &:hover {
    box-shadow: 2px 2px 5px 0px $secondaryTextColor;
  }

  &.disabled,
  &.disabled:hover {
    border-color: $mainIconColor;
    color: $mainIconColor;
    cursor: default;
    box-shadow: unset;
  }
}

.icon {
  height: 15px;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root_isDark {
  &.default {
    color: $cardColor_dark;
    background-color: $primaryColor;

    &:hover {
      box-shadow: $primaryBoxShadow_dark;
    }
  }

  &.link {
    color: $primaryColor;

    .icon {
      color: $secondaryTextColor_dark;
    }

    &.disabled,
    &.disabled:hover {
      background: unset;
    }
  }

  &.transparent {
    border: 1px solid $primaryColor;
    color: $primaryTextColor_dark;

    &:hover {
      .icon {
        color: $darkBgColor;
      }
    }

    .icon {
      color: $darkTextColor;
    }

    &.disabled,
    &.disabled:hover {
      background: unset;
      border-color: $mainIconColor;
      color: $mainIconColor;
    }
  }

  .icon {
    color: $darkBgColor;
  }

  &.disabled,
  &.disabled:hover {
    border-color: $disabledButtonBgColor;
    background: $disabledButtonBgColor;
    color: $mainIconColor;
    box-shadow: unset;
  }
}
