@import 'variables/stylesVariables';

.root {
  position: relative;
  box-sizing: border-box;
  width: 100%;

  table {
    width: 100%;
    padding: 0;
    margin: 0;

    thead {
      position: sticky;
      top: 42px;
      box-sizing: border-box;
      z-index: 10;
    }

    th,
    td {
      border: 1px solid #ddd;
      text-align: center;
      width: max-content;
      box-sizing: border-box;

      &:first-child {
        width: 100px;
      }
    }

    th {
      height: 30px;
      background-color: $primaryColor;
      color: $mainBgColor;
      border-top: none;
      border-bottom: none;

      &:first-child {
        border-left: none;
      }

      &:last-child {
        border-right: none;
      }
    }

    td {
      height: 38px;
      font-size: 14px;
    }
  }
}

.cell {
  position: relative;
  background-color: #fff;

  .scheduleCard {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primaryColor;
    font-size: 10px;
    padding: 4px 8px;
    box-sizing: border-box;
    color: #fff;

    &__fio {
      vertical-align: middle;
      overflow: hidden;
      text-overflow: ellipsis;
    //   height: 100%;
    }

    &:hover {
      cursor: pointer;
      background-color: $blueCardHoverBgColor;
    }
  }

  &_notFull {
    background: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0) 48%,
        rgba(0, 0, 0, 0.35) 50%,
        rgba(0, 0, 0, 0) 52%
      ),
      linear-gradient(-45deg, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, 0.35) 50%, rgba(0, 0, 0, 0) 52%);
    background-size: 1em 1em;
    background-color: #fff;
  }

  &:hover {
    cursor: pointer;
    background-color: bisque;

    .scheduleCard {
      background-color: $blueCardHoverBgColor;
    }
  }
}
