.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
}

.block_1 {
  > * {
    width: 50%;
  }
}

.block_2 {
  > * {
    width: calc(percentage(1) / 3);
  }
}

.block_1,
.block_2,
.block_3 {
  display: flex;
  gap: 20px;
}

.block_3 {
  width: 100%;

  > * {
    width: 100%;
  }
}

.blockContent {
  margin-top: 20px;
}
