@import 'variables/stylesVariables';

.backplate {
  width: 100%;
  height: $headerHeight;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 15;
}

.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 $sideSpace;
  box-sizing: border-box;
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 55px;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100%;
  }
}

.themeToggle {
  margin-left: auto;
  margin-right: 30px;
}
