@import 'variables/stylesVariables';

* {
  box-sizing: border-box;
}

.root {
  width: $tightWidth;
  min-width: $tightWidth;
  position: relative;
  transition: 0.2s all ease-in;

  &_isExpanded {
    transition: 0.2s all ease-in;
    width: $expandedWidth;
    min-width: $expandedWidth;
  }
}

.topNav {
  display: flex;
  justify-content: flex-end;
  padding: 0 37px 0 36px;
  margin: 5px 0 20px;
}

.menu {
  &__item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    user-select: none;
    line-height: 1;
    overflow: hidden;

    &_isCurrent {
      .title {
        color: $primaryColor !important;
      }
    }

    .title {
      padding: 18px 36px;
      display: flex;
      align-items: center;
      font-size: 20px;
      color: $mainIconColor;

      &__icon {
        margin-right: 26px;
      }

      &__caption,
      &__caption_hidden {
        margin-right: 12px;
        transition: 0.2s all ease;
      }

      &__caption_hidden {
        visibility: hidden;
      }

      &:hover {
        color: $primaryTextColor;
        background: $mainBgColor;
      }
    }

    &_isDark {
      .title {
        &:hover {
          color: $primaryTextColor_dark;
          background: $mainBgColor_dark;
        }
      }
    }

    .submenu {
      display: flex;
      flex-direction: column;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;

      &_expand {
        opacity: 1;
        position: relative;
        transition: 0.25s transform;
        z-index: 1;
      }

      &_hide {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }

      &__item {
        padding: 14px 30px;
        padding-left: 80px;
        cursor: pointer;
        user-select: none;
        line-height: 1;
        font-size: 16px;
        color: $mainIconColor;

        &:hover {
          color: $primaryTextColor;
          background: $mainBgColor;
        }
      }
    }
  }
}