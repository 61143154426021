@import 'variables/stylesVariables';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  position: relative;
  background-color: $mainBgColor;
  overflow: hidden;
  @include themeSwitchTransition;

  &_isDark {
    background-color: $mainBgColor_dark;
    @include themeSwitchTransition;
  }
}

.backplate {
  display: flex;
  box-sizing: border-box;
  position: relative;
  padding: 20px $sideSpace;
  overflow: hidden;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.sidebar {
  margin-right: 20px;
}
