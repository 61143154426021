@import 'variables/stylesVariables';

@mixin th-styles {
  color: $secondaryTextColor;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
}

.root {
  overflow: auto;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;

  @include scroll-styles;

  table {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    border-collapse: collapse;

    thead {
      position: sticky;
      top: 0;
      background-color: #fff;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: 1px solid $secondaryTextColor;
        width: 100%;
      }

      th {
        border-bottom: 1px solid $secondaryTextColor;
        padding: 20px 22px;
        @include th-styles;

        .sortField {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          @include th-styles;

          &__sortIcon {
            display: flex;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.row {
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid$primaryBorderColor;
  }

  &:hover {
    background: $mainBgColor;

    td {
      color: $primaryColor;

      a {
        color: $primaryColor;
      }
    }
  }

  td {
    padding: 8px 22px;
    font-size: 14px;
    font-weight: 400;
  }
}
