@import 'variables/stylesVariables';

$borderRadius: 4px;
$defaultColor: rgba($primaryColor, 0.8);
$hoverColor: $primaryColor;

.root {
  position: relative;
  box-sizing: border-box;
  width: min-content;
  min-width: 80px;
  cursor: pointer;
  background-color: #fff;

  &__arrow {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
  }

  &__placeholder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $defaultColor;
    color: $defaultColor;
    border-radius: $borderRadius;
    box-sizing: border-box;
    padding: 10px;
    position: relative;

    &:hover {
      border-color: $hoverColor;
      color: $hoverColor !important;
    }
  }

  &__select {
    box-sizing: border-box;
    border-radius: $borderRadius;
    box-shadow: 0 3px 3px 1px rgba($primaryColor, 0.5);
    width: 98.5%;
    margin: 0 auto;
    padding: 4px 0;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    z-index: 10;
    height: 200px;
    overflow-y: scroll;
    @include scroll-styles;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primaryColor;
    }
  }

  &__option {
    list-style: none;
    margin: 0;
    padding: 5px 10px;
    box-sizing: border-box;
    text-align: left;
    color: $primaryColor;

    &:hover {
      background: $hoverColor;
      color: #fff;
    }
  }

  &_isOpen {
    .root {
      &__placeholder {
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
      }

      &__select {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
      }
    }
  }
}
