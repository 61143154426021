@import 'variables/stylesVariables';

.root {
  display: flex;
  width: 100%;
  flex-grow: 1;

  &_centered {
    justify-content: center;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  // flex-grow: 1;
}
