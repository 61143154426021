@import 'variables/stylesVariables';

.root {
  font-size: 40px;
  font-weight: 700;
  box-sizing: border-box;
  color: $secondaryTextColor;
  width: 100%;
  padding: 0 85px;
  margin-bottom: 40px;
  display: flex;
  gap: 20px;
  align-items: center;

  .backLink svg {
    color: $secondaryTextColor;
  }

  &_isDark {
    color: $secondaryTextColor_dark;
  }
}
