@import 'variables/stylesVariables';

.title {
  font-size: 24px;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  color: $errorColor;
  padding-top: 20vh;
  padding: 20vh 20px 0;
}
