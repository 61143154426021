@import 'variables/stylesVariables';

.root {
  display: flex;
}

.form {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding-left: 85px;

  &__fields {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
}

.bottomPanel {
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 10px;
}

.bgIcon {
  color: $mainBgColor !important;
  margin-left: auto;
  padding: 165px 85px 0;

  &_isDark {
    color: $mainBgColor_dark;
  }
}

.errors {
  margin-top: 10px;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  display: block;
  color: $errorColor;
  font-size: 12px;
  line-height: 14px;
}
