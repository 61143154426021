@import 'variables/stylesVariables';

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.2);

  &__avatar {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      display: block;
      object-fit: cover;
      box-sizing: border-box;
      object-fit: cover;
    }
  }

  &__initials {
    color: rgba(0, 0, 0, 0.8);
    font-weight: 700;
    line-height: 1;
    box-sizing: border-box;
  }

  &__placeholder {
    color: $mainIconColor;
  }

  &_isDark {
    background-color: $cardColor_dark;
  }
}

.small {
  width: 40px;
  height: 40px;
}

.medium {
  width: 50px;
  height: 50px;
}

.large {
  width: 60px;
  height: 60px;
}

.extralarge {
  width: 80px;
  height: 80px;
}
